
























































































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'ChemMcM1AA3Lab9Results1',
  components: {
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        partAExplanation: null,
        massSA: null,
        mwSA: null,
        molSA: null,
        // volAA: null,
        // massAA: null,
        // mwAA: null,
        // molAA: null,
        observations: null,
      },
    };
  },
});
